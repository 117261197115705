import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "../store/slices/auth";
import { useAuth } from "../Hooks/useAuth";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";

const ProtectedRoute = ({ component: Component, render, ...rest }) => {
  const auth = useSelector(selectAuth);
  const { data, isLoading } = useAuth();

  if (isLoading) return <FullLoadingScreen />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.jwt !== null || data.jwt !== null) {
          return Component ? <Component {...props} /> : render(props);
        } else {
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
