import { object } from "yup";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link, useHistory } from "react-router-dom";
import { Form } from "@shared/components/Form";
import { BiLogOut } from "react-icons/bi";
import { reduceCollectedPaymentObject, getLocalToken } from "@shared/functions";
import { logout } from "../store/slices/auth";
import { useSelector, useDispatch } from "react-redux";
import { selectNewLoansAll, selectTotalNewLoans } from "../store/slices/newLoans";
import { selectNewDebtors } from "../store/slices/newDebtors";
import { selectCommon } from "../store/slices/common";
import { selectQueues, selectSyncronizations } from "../store/slices/syncronization";
import { selectDevice } from "../store/slices/miscData";
import { selectCollectedAll, selectTotalCollected } from "../store/slices/payments";
import { selectGastosAll, selectTotalGastos } from "../store/slices/gastos";
import WrongCuadreWarning from "../components/WrongCuadreWarning";
import { useAlert } from "react-alert";
import { uploadCobroCuadre } from "@shared/api";
import api from "../store/slices/api";
import { useAuth } from "../Hooks/useAuth";

const Logout = () => {
  const auth = useAuth();
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();

  const queues = useSelector(selectQueues);
  const common = useSelector(selectCommon);
  const gastos = useSelector(selectGastosAll);
  const newLoans = useSelector(selectNewLoansAll);
  const newDebtors = useSelector(selectNewDebtors);
  const collected = useSelector(selectCollectedAll);
  const syncronizations = useSelector(selectSyncronizations);
  const device = useSelector(selectDevice);
  const totalGastos = useSelector(selectTotalGastos);
  const totalCollected = useSelector(selectTotalCollected);
  const totalNewLoans = useSelector(selectTotalNewLoans);
  const filteredQueues = queues.filter((q) => q.requiredData === true);

  const validation = object({});

  const button = { icon: <BiLogOut />, label: "Salir", variant: "contained", color: "secondary" };

  // const jsonValue = localStorage.getItem("localCommon");
  // const localCommon = jsonValue != null ? JSON.parse(jsonValue) : null;

  const backupDataOnLogout = async () => {
    const payments = reduceCollectedPaymentObject(collected);

    const efectivo =
      totalNewLoans.adelanto +
      common.bank_amount +
      totalNewLoans.actanotarial +
      totalCollected.collectedCash +
      totalCollected.mora -
      totalGastos -
      totalNewLoans.entregas;

    const token = getLocalToken();

    // payments: payments.reverse(), // Didnt want to work for unknown reason
    // newLoans: newLoans.reverse(), // Didnt want to work for unknown reason
    // gastos: gastos.reverse(), // Didnt want to work for unknown reason
    const data = {
      device: { ...device, token },
      payments,
      newLoans,
      gastos,
      newDebtors,
      cuadre: { ...common, efectivo },
      syncronizations,
    };

    try {
      await uploadCobroCuadre(data);
      dispatch(logout());
      dispatch(
        api.util.invalidateTags([
          "debtorsTag",
          "ownerConfigTag",
          "routeTag",
          "userTag",
          "userConfigTag",
          "permissionsTag",
        ])
      );

      localStorage.removeItem("recData");
      localStorage.removeItem("syncProps");
      localStorage.removeItem("newData");
      localStorage.removeItem("awaitingAction");

      auth.logout();

      history.replace("/");

      alert.success("Cuadre Cerrado!", { position: "bottom center", timeout: 10000 });
    } catch (error) {
      console.log(error);
    }
  };

  const submithandler = () => backupDataOnLogout();

  return (
    <Card className="p-3 mt-2">
      <WrongCuadreWarning />

      {filteredQueues.length > 0 && (
        <Box component="div">
          <div className="alert alert-warning">
            <Typography variant="body1" component="p" paragraph>
              <b>Este dispositivo a&uacute;n tiene {filteredQueues.length} dato(s) que son requeridos sin guardar.</b>
            </Typography>

            <Typography variant="body1" component="p" paragraph>
              Por favor, mant&eacute;ngase conectado al internet hasta que este mensaje desaparezca automaticamente.
            </Typography>
          </div>
        </Box>
      )}

      <Typography variant="h6" component="h3" gutterBottom>
        Salir y Guardar!
      </Typography>

      <Typography variant="body1" component="p" paragraph>
        Solamente los datos en este dispositivo seran reseteado!
      </Typography>

      <Typography variant="body1" component="p" paragraph>
        Esta seguro de querer resetear los datos de este dispositivo?
      </Typography>

      <div className="container mt-4">
        <Form initialValues={{}} validation={validation} submithandler={submithandler} button={button}></Form>
      </div>

      <div className="container mt-4">
        <Link className="text-danger" to="/">
          <u>Volver a Atr&aacute;s</u>
        </Link>
      </div>
    </Card>
  );
};

export default Logout;
