import PouchDB from "pouchdb-browser";

export default function useGetdoc(dbName) {
  const getDoc = async (docId) => {
    const db = new PouchDB(dbName);
    try {
      return await db.get(docId);
    } catch (error) {
      console.error("Error reading document:", error);
    }
  };
  return getDoc;
}
