import { useState, useEffect, useContext, createContext } from "react";
import api from "@shared/server/api";

const authContext = createContext();
// Provider component that wraps your app and makes auth object
// ... available to any child component that calls useAuth()
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object
// .. and re-render when it changes
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const initialValue = { user: {}, jwt: null };
  const [data, setData] = useState(initialValue);
  const [errors, setErrors] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const localKey = "cobro_auth";

  useEffect(() => {
    if (data.jwt === null) {
      const localUser = localStorage.getItem(localKey);
      if (localUser) {
        setData(JSON.parse(localUser));
      } else {
        setData(initialValue);
      }
    }

    setLoading(false);
  }, []);

  async function login(data) {
    setErrors(null);
    setLoading(true);

    try {
      const ipAddress = "1.0.0.27";
      const { data: user } = await api.post("/cobro/login/v2", {
        data: { ...data, ipAddress },
        foceAppUpdateNumber: import.meta.env.VITE_foceAppUpdateNumber,
      });

      localStorage.setItem(localKey, JSON.stringify(user));
      setData(user);
      setLoading(false);

      return user;
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setErrors(error.response.data.message);
      } else {
        setErrors(error.message);
      }
    }
  }

  function logout() {
    localStorage.removeItem(localKey);
    setData(initialValue);
  }

  // Return the user object and auth methods
  return { data, errors, isLoading, login, logout };
}
