import { useState, useEffect } from "react";
import PendingDataWarning from "@shared/components/PendingDataWarning";
import AppUpdateModal from "@shared/components/AppUpdateModal";
import Container from "@material-ui/core/Container";
// import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Redirect, Link } from "react-router-dom";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import * as mobileDetect from "react-device-detect";
import RenderLoginForm from "@shared/components/RenderLoginForm";
// import OldDomainWarning from "@shared/components/OldDomainWarning";
import { useDispatch, useSelector } from "react-redux";
import { authThunk, resetAuth } from "../store/slices/auth";
import { selectAuth } from "../store/slices/auth";
import { setDevice } from "../store/slices/miscData";
import { selectQueues } from "../store/slices/syncronization";
import { setDisplayNav } from "../store/slices/ui";
import { useAuth } from "../Hooks/useAuth";
import localDB from "../Hooks/usePouch";

import api from "@shared/server/api";
import { getLocalToken } from "@shared/functions";

const Login = () => {
  const payments_db = localDB("payments");
  const gastos_db = localDB("gastos");
  const loans_db = localDB("loans");
  const debtors_db = localDB("debtors");

  const [errorDisplay, setErrorDisplay] = useState(null);
  const auth = useAuth();
  const authDiscontinued = useSelector(selectAuth);
  const queues = useSelector(selectQueues);
  const dispatch = useDispatch();
  const [appUpdate, setAppUpdate] = useState(false);
  const { browserName, fullBrowserVersion, mobileVendor, mobileModel, osVersion, getUA } = mobileDetect;
  const filteredQueues = queues.filter((q) => q.requiredData === true);

  const getDeviceSpecs = () => {
    let displayMode = "Browser";
    if (window.matchMedia("(display-mode: standalone)").matches) {
      displayMode = "PWA Standalone";
    }

    return {
      displayMode,
      appVersion: import.meta.env.VITE_VERSION,
      foceAppUpdate: import.meta.env.VITE_foceAppUpdateNumber,
      browserName,
      fullBrowserVersion,
      mobileVendor,
      mobileModel,
      osVersion,
      getUA,
    };
  };

  useEffect(() => {
    dispatch(resetAuth());
    dispatch(setDisplayNav({ DisplayNav: false }));
    dispatch(setDevice(getDeviceSpecs()));
  }, []);

  // useEffect(() => {
  //   const interval = setTimeout(() => {
  //     dispatch(resetAuth());
  //   }, 5000);

  //   return () => clearTimeout(interval);
  // }, [auth.error]);

  if (authDiscontinued.status === "loading" || authDiscontinued.commonStatus === "loading")
    return <FullLoadingScreen />;
  if (authDiscontinued.customer_id) return <Redirect to={`/customer/disabled/${authDiscontinued.customer_id}`} />;
  if (authDiscontinued.jwt && authDiscontinued.commonStatus === "succceed") return <Redirect to="/swr/fetcher" />;

  const submithandler = async (data) => {
    const localCommonKey = "localCommonv2";
    try {
      const token = getLocalToken();

      const rData = await auth.login({ ...data, token });
      const { user_id, creditor_id } = rData.user;

      if (rData.action === "routeDisabled") {
        setErrorDisplay("Su cuenta esta desabilitada por falta de pago!");
        return;
      }

      const { data: dbCommon } = await api.post("/cobro/cuadre/init", {
        creditor_id: creditor_id,
        user_id: user_id,
        token,
        env: import.meta.env.VITE_ENV,
        appVersion: import.meta.env.VITE_VERSION,
      });

      let localCommon = {};
      const localCommonData = localStorage.getItem(localCommonKey) ?? null;

      if (localCommonData) {
        localCommon = JSON.parse(localCommonData);
      }

      if (localCommon.cuadre_id !== dbCommon.cuadre_id) {
        localStorage.setItem(localCommonKey, JSON.stringify(dbCommon));

        // payments_db.deleteDatabase();
        // gastos_db.deleteDatabase();
        // loans_db.deleteDatabase();
        // debtors_db.deleteDatabase();
      }

      if (dbCommon && rData && auth.data && auth.isLoading === false) {
        dispatch(authThunk({ data }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    // <OldDomainWarning url="https://beta-cobro.wprestamos.com" label="beta-cobro.wprestamos.com" />
    <Container fixed maxWidth="sm">
      {appUpdate && <AppUpdateModal />}

      {authDiscontinued.commonError && (
        <div className="alert alert-danger mt-3">
          <Typography variant="subtitle2" component="p">
            {authDiscontinued.commonError}
          </Typography>
        </div>
      )}

      {filteredQueues.length > 0 ? (
        <PendingDataWarning pendingData={filteredQueues} />
      ) : (
        <>
          {errorDisplay && (
            <div className="alert alert-danger mt-3">
              <Typography variant="subtitle2" component="p">
                {errorDisplay}
              </Typography>
            </div>
          )}

          {auth.errors && (
            <div className="alert alert-danger mt-3">
              <Typography variant="subtitle2" component="p">
                {auth.errors}
              </Typography>
            </div>
          )}

          <RenderLoginForm submithandler={submithandler} username={authDiscontinued.username} />
        </>
      )}

      {/* <div style={{ marginTop: 160, marginBottom: 50 }} className="text-center">
        <Link to="/clear/app/data" className="h6">
          <span className="text-danger">Reiniciar Aplicaci&oacute;n (Borrar Todo)</span>
        </Link>
      </div> */}
    </Container>
  );
};

export default Login;
