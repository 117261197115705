import { useState } from "react";
import { useParams } from "react-router";
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { printAmount, getUniqueID } from "@shared/functions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CancelIcon from "@material-ui/icons/Cancel";
import Checkbox from "@material-ui/core/Checkbox";
import SubmitButton from "@shared/components/SubmitButton";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Loading from "@shared/components/Loading";
import { useSelector, useDispatch } from "react-redux";
import RenderBackButton from "@shared/components/RenderBackButton";
import { deleteNewLoan, selectNewLoanById } from "../store/slices/newLoans";
import { createQueue } from "../store/slices/syncronization";
import { selectCommon } from "../store/slices/common";
import { selectCollected } from "../store/slices/payments";
import { deletePayment } from "../store/slices/payments";
// import { undoPayment } from "../store/slices/loans";
import { useHistory } from "react-router-dom";
import { useGetPermissionsQuery } from "../store/slices/api";
import { useAlert } from "react-alert";
import localDB from "../Hooks/usePouch";

const DeleteNewLoan = () => {
  const payments_db = localDB("payments");
  const loans_db = localDB("loans");

  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const common = useSelector(selectCommon);

  const [isDelete, setDelete] = useState(true);
  const { id: money_id } = useParams();
  const currentLoan = useSelector((state) => selectNewLoanById(state, money_id));
  const payments = useSelector(selectCollected);

  const { data: permissions } = useGetPermissionsQuery({ creditor_id: common.creditor_id, user_id: common.user_id });

  const handleDeleteEntrega = async () => {
    const payment = payments.find((x) => !x.deleted && x.payoffLoanId === currentLoan.money_id) || null;

    let paymentPostData = null;
    let paymentSyncProps = null;
    let applyLoan = null;

    if (payment && isDelete === true) {
      applyLoan = {
        money_id: payment.money_id,
        paymentAmount: payment.paymentAmount,
        paymentMora: payment.paymentMora,
      };

      paymentPostData = {
        payment_id: payment.payment_id,
        money_id: payment.money_id,
        debtor_id: payment.debtor_id,
        pUniqueId: payment.pUniqueId,
        user_id: common.user_id,
      };

      paymentSyncProps = {
        data_id: payment.payment_id,
        syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
        queueTime: Math.floor(Date.now()),
        endPoint: "/bgsync/cobro/payment/delete",
        syncType: "deletePayment",
        syncAmount: payment.paymentAmount,
        syncTitle: "Pago Renovo Borrado",
        syncName: `Cliente > ${payment.name}`,
        requiredData: true,
      };

      if (permissions.perm_loan === true) {
        payments_db.deleteDoc(payment);
        dispatch(deletePayment(payment));
        // dispatch(undoPayment(applyLoan));
        dispatch(createQueue({ postData: paymentPostData, syncProps: paymentSyncProps }));

        alert.error("Pago Borrado!");
      }
    }

    const newLoanPostData = {
      money_id: currentLoan.money_id,
      mUniqueId: currentLoan.mUniqueId,
      debtor_id: currentLoan.debtor_id,
      user_id: common.user_id,
    };

    const newLoanSyncProps = {
      data_id: currentLoan.money_id,
      syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
      queueTime: Math.floor(Date.now()),
      endPoint: "/bgsync/cobro/loan/delete",
      syncType: "deleteLoan",
      syncAmount: currentLoan.amount,
      syncTitle: "Entregada Borrada",
      syncName: `Cliente > ${currentLoan.name}`,
      requiredData: true,
    };

    if (permissions.perm_loan === true) {
      loans_db.deleteDoc(currentLoan);

      dispatch(deleteNewLoan(currentLoan));
      dispatch(createQueue({ postData: newLoanPostData, syncProps: newLoanSyncProps }));

      history.replace("/entregas");
      alert.error("Entrega Borrada!");
    } else {
      const awaitingAction = {
        currentLoan,
        newLoanSyncProps,
        newLoanPostData,
        paymentPostData,
        payment,
        applyLoan,
        paymentSyncProps,
      };

      localStorage.setItem("awaitingAction", JSON.stringify({ ...awaitingAction, type: "deleteNewLoan" }));
      history.replace("/access/codes");
    }
  };

  if (!permissions) return <Loading />;

  return (
    <div className="container mt-5">
      <Card>
        <CardContent>
          {!permissions.perm_loan && (
            <Box className="text-danger" paddingBottom={2}>
              <Divider className="mt-2" />
              <Typography variant="h6" component="h4" gutterBottom>
                No autorizado
              </Typography>
              <Typography variant="body1" component="p" paragraph>
                Usted no esta autorizado para borrar entregas en la app de cobro!
              </Typography>

              <Typography variant="body1" component="p" paragraph>
                Al continuar va requerir de una codigo de aceso!
              </Typography>
              <Divider className="mt-2" />
            </Box>
          )}
          <Typography variant="h5" component="h3" className="text-danger">
            Confirmaci&oacute;n
          </Typography>

          <Typography variant="h6" component="h4" gutterBottom>
            Decea borrar esta entrega?
          </Typography>
          <Typography variant="body2" component="p" paragraph>
            Entrega de {printAmount(currentLoan.amount)} para {currentLoan.name}
          </Typography>

          <FormGroup row className="mt-2">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isDelete}
                  onChange={() => setDelete(!isDelete)}
                  name="deleteCollected"
                  color="secondary"
                />
              }
              label="Borrar pago(s) aplicado con esta entrega?"
            />
          </FormGroup>

          <Box paddingTop={2}>
            {/* <Button fullWidth variant="contained" color="secondary" onClick={() => handleDeleteEntrega(currentLoan)}>
              Cancelar Entrega
            </Button> */}

            <SubmitButton
              autoFetch={false}
              color="secondary"
              text="Cancelar Entrega!"
              startIcon={<CancelIcon />}
              callBack={handleDeleteEntrega}
            />
          </Box>

          <div className="mt-4 mb-2">
            <RenderBackButton />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeleteNewLoan;
