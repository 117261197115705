import { useEffect, useState } from "react";
import PouchDB from "pouchdb-browser";

export default function usePouchDB(dbName, options = { fetch_docs: false, include_docs: false }) {
  const [docs, setDocs] = useState([]);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const db = new PouchDB(dbName);

  useEffect(() => {
    // Fetch initial data
    if (options.fetch_docs === true) getAllDocs(options);
  }, [dbName, result]);

  const getAllDocs = async (options) => {
    return true;
    try {
      const result = await db.allDocs(options);
      if (options.include_docs === false) {
        setDocs(result.rows);
      } else {
        setDocs(result.rows.map((row) => row.doc));
      }
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const getDoc = async (docId, options = {}) => {
    return true;
    try {
      const result = await db.get(docId, options);

      setResult(result);
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const putDoc = async (doc, options = {}) => {
    return true;
    try {
      const result = await db.put(doc, options);
      setResult(result);
      return result;
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const bulkDocs = async (docs, options = {}) => {
    return true;
    try {
      const result = await db.bulkDoc(docs, options);

      setResult(result);
      return result;
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const deleteDoc = async (docId, docRev) => {
    return true;
    try {
      const result = await db.remove(docId, docRev);
      setResult(result);
      return result;
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const getDatabaseInfo = async () => {
    return true;
    try {
      const result = await db.info();
      setResult(result);
      return result;
    } catch (err) {
      console.log(err);
    }
  };

  const deleteDatabase = async () => {
    return true;
    try {
      const result = await db.detroy();
      console.log("db delete", result);
      setResult(result);
      return result;
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  return { docs, result, error, getDoc, putDoc, deleteDoc, bulkDocs, getDatabaseInfo, deleteDatabase };
}
